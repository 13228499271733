import React, { useState, useContext } from "react";
import { AppIcon, Select } from "@virtualcapital/utogi-ui-library";
import { AuthContext } from "@virtualcapital/utogi-auth-library";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import queryString from "query-string";

import config from "config";

import "./welcome.scss";
import { Modules } from "enums/modules";

const Welcome = () => {
  const GET_USER_ROLES = gql`
    query {
      auth {
        session {
          roles {
            title
            id
            office {
              name
            }
          }
        }
      }
    }
  `;
  const { data } = useQuery(GET_USER_ROLES);

  const userRoles = data?.auth?.session?.roles?.map(
    ({ title, id: value, office: { name: office } }: any) => ({
      value,
      name: `${title} - ${office}`,
    })
  );

  const {
    authData: { token },
  } = useContext(AuthContext);

  const [role, selectRole] = useState<any>(null);
  const generateLoginURL = (module: string) => {
    const redirectTo = `${config.DOMAIN_PROTOCOL}${module}.${config.DOMAIN_SUFFIX}/authenticate`;
    return `${config.DOMAIN_PROTOCOL}auth.${
      config.DOMAIN_SUFFIX
    }/auth?${queryString.stringify({
      redirectTo,
      module: module.toUpperCase(),
      role,
      token,
    })}`;
  };
  return (
    <div className="welcome">
      <h2>Welcome to Utogi</h2>
      {userRoles && (
        <div className="switch-role">
          <Select
            options={userRoles}
            placeholder="Select an user role"
            value={role}
            onChange={(value: string) => selectRole(value)}
          />
        </div>
      )}
      <div className="app-switcher-list">
        <a
          href={generateLoginURL(Modules.MAPS)}
          className="app-switcher-list-item box"
        >
          <AppIcon icon="maps" />
          Maps
        </a>
        <a
          href={generateLoginURL(Modules.CONTACTS)}
          className="app-switcher-list-item box"
        >
          <AppIcon icon="contacts" />
          Contact
        </a>
        <a
          href={generateLoginURL(Modules.MARKETING)}
          className="app-switcher-list-item box"
        >
          <AppIcon icon="marketing" />
          Marketing
        </a>
        <a
          href={generateLoginURL(Modules.EMAIL)}
          className="app-switcher-list-item box"
        >
          <AppIcon icon="email" />
          Email
        </a>
      </div>
    </div>
  );
};

export default Welcome;
