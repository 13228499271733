import React, { useState, useEffect } from "react";
import { Input, Button } from "@virtualcapital/utogi-ui-library";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";

import GoogleLogin from "containers/GoogleLogin";
import Loader from "components/loader";

import { isEmail } from "helpers/validation";
import { saveToken, getToken, removeToken } from "service";

import "./login.scss";

type Errors = {
  email: null | string;
  password: null | string;
  async: [];
};

const Login = ({ location: { search } }: any) => {
  const { module, redirectTo, role } = queryString.parse(search);
  const authToken = getToken();
  const [fields, onFieldChange] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<any>({
    email: null,
    password: null,
    async: [],
  });
  const MANUAL_LOGIN = gql`
    mutation(
      $email: String!
      $password: String!
      $module: ApplicationModules!
    ) {
      auth {
        login(email: $email, password: $password, module: $module) {
          token
        }
      }
    }
  `;

  const GOOGLE_LOGIN = gql`
    mutation($token: String!, $module: ApplicationModules!) {
      auth {
        googleLogin(token: $token, module: $module) {
          token
        }
      }
    }
  `;

  const AUTHENTICATE = gql`
    mutation($token: String!, $role: String, $module: ApplicationModules!) {
      auth {
        authenticate(token: $token, module: $module, role: $role) {
          token
        }
      }
    }
  `;

  const [
    manualLogin,
    { loading: isManualLoginLoading, error: manualLoginError },
  ] = useMutation(MANUAL_LOGIN, {
    update(_cache, { data }) {
      if (data?.auth?.login?.token) {
        const {
          auth: {
            login: { token },
          },
        } = data;
        saveToken(token);
        if (redirectTo) {
          window.location.replace(`${redirectTo.toString()}?token=${token}`);
        } else {
          window.location.replace(`/authenticate?token=${token}`);
        }
      }
    },
  });

  const [
    googleLogin,
    { loading: isGoogleLoginLoading, error: googleLoginError },
  ] = useMutation(GOOGLE_LOGIN, {
    update(_cache, { data }) {
      if (data?.auth?.googleLogin?.token) {
        const {
          auth: {
            googleLogin: { token },
          },
        } = data;
        saveToken(token);
        if (authToken) {
          if (redirectTo) {
            window.location.replace(`${redirectTo.toString()}?token=${token}`);
          } else {
            window.location.replace(`/authenticate?token=${token}`);
          }
        }
      }
    },
  });

  const [
    authenticate,
    { loading: isAuthenticateLoading, error: authenticateError },
  ] = useMutation(AUTHENTICATE, {
    update(_cache, { data }) {
      if (data?.auth?.authenticate?.token) {
        const {
          auth: {
            authenticate: { token },
          },
        } = data;
        if (authToken) {
          if (redirectTo) {
            window.location.replace(`${redirectTo.toString()}?token=${token}`);
          } else {
            window.location.replace(`/authenticate?token=${token}`);
          }
        }
      }
    },
  });

  const onSubmit = () => {
    const newErrors: Errors = {
      email: null,
      password: null,
      async: [],
    };
    let hasError = false;
    const { email, password } = fields;

    if (!email || email === "") {
      newErrors.email = "Email is required.";
      hasError = true;
    }
    if (email && !isEmail(email)) {
      newErrors.email = "Email is invalid.";
      hasError = true;
    }
    if (!password || password === "") {
      newErrors.password = "Password is required";
      hasError = true;
    }
    setErrors(newErrors);
    if (!hasError) {
      manualLogin({
        variables: { email, password, module: module || "PROFILE" },
      });
    }
  };

  useEffect(() => {
    console.log({ authToken });
    if (authToken && !authenticateError) {
      authenticate({
        variables: {
          token: authToken,
          module: module || "PROFILE",
          role: role || "",
        },
      });
    }
    if (manualLoginError) {
      setErrors({
        email: null,
        password: null,
        async: manualLoginError.graphQLErrors.map(({ message }) => message),
      });
    }
    if (googleLoginError) {
      setErrors({
        email: null,
        password: null,
        async: googleLoginError.graphQLErrors.map(({ message }) => message),
      });
    }
    if (authenticateError) {
      removeToken();
    }
  }, [
    manualLoginError,
    googleLoginError,
    authenticateError,
    authToken,
    module,
    role,
  ]);

  const onGoogleLoginSuccess = (response: any) => {
    googleLogin({
      variables: { token: response.accessToken, module: module || "PROFILE" },
    });
  };

  const getContent = () => {
    if (isAuthenticateLoading) {
      return <Loader />;
    }
    return (
      <>
        <div className="form-group">
          <Input
            placeholder="Email"
            onChange={(email: any) => onFieldChange({ ...fields, email })}
            error={errors.email}
            value={fields.email}
          />
        </div>
        <div className="form-group">
          <Input
            placeholder="password"
            type="password"
            onChange={(password: any) => onFieldChange({ ...fields, password })}
            error={errors.password}
            value={fields.password}
          />
        </div>
        <div className="error-container">
          {errors.async.map((error: string, key: number) => (
            <div className="alert alert-danger" key={key}>
              {error}
            </div>
          ))}
        </div>
        <div className="button-container">
          <Button hasShadow onClick={onSubmit} loading={isManualLoginLoading}>
            Sign In
          </Button>
          <GoogleLogin
            onSuccess={onGoogleLoginSuccess}
            loading={isGoogleLoginLoading}
            onFailure={() => {}}
            cookiePolicy={"single_host_origin"}
          />
        </div>
        <p className="links">
          Forgot you password? <Link to="/reset-password">Reset Password</Link>
        </p>
      </>
    );
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="logo-container">
          <img alt="Utogi Logo" src={require("assets/logo/utogi-logo.png")} />
        </div>
        {getContent()}
      </div>
    </div>
  );
};

export default withRouter(Login);
