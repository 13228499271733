import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Input, Button } from "@virtualcapital/utogi-ui-library";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { isPasswordStrong } from "helpers/validation";

import GoogleLogin from "containers/GoogleLogin";

import config from "config";

import "./getStarted.scss";

type Errors = {
  password: null | string;
  confirmPassword: null | string;
  async: [];
};

const GetStarted = () => {
  const { token } = useParams();
  const [fields, onFieldChange] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<any>({
    password: null,
    confirmPassword: null,
    async: [],
  });
  const [isSuccessful, toggleSuccessful] = useState<any>(false);
  const SETUP_ACCOUNT = gql`
    mutation($token: String!, $password: String, $googleToken: String) {
      auth {
        activateAccount(
          token: $token
          password: $password
          googleToken: $googleToken
        ) {
          success
        }
      }
    }
  `;

  const [setupAccount, { loading, error }] = useMutation(SETUP_ACCOUNT, {
    update(_cache, { data }) {
      if (data?.auth?.activateAccount?.success) {
        toggleSuccessful(true);
        window.location.replace(
          `${config.DOMAIN_PROTOCOL}auth.${config.DOMAIN_SUFFIX}/login`
        );
      }
    },
  });

  const onGoogleLoginSuccess = (response: any) => {
    setupAccount({ variables: { googleToken: response.accessToken, token } });
  };

  const onSubmit = () => {
    const newErrors: Errors = {
      password: null,
      confirmPassword: null,
      async: [],
    };
    let hasError = false;
    const { password, confirmPassword } = fields;

    if (!password || password === "") {
      newErrors.password = "Password is required.";
      hasError = true;
    } else if (!isPasswordStrong(password)) {
      newErrors.password =
        "At least 8 characters including a number, special character, lowercase, and uppercase letters";
      hasError = true;
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm Password is required.";
      hasError = true;
    } else if (confirmPassword !== password) {
      newErrors.confirmPassword = "Passwords do not match.";
      hasError = true;
    }
    setErrors(newErrors);
    if (!hasError) {
      setupAccount({ variables: { token, password } });
    }
  };

  useEffect(() => {
    if (error) {
      setErrors({
        email: null,
        password: null,
        async: error.graphQLErrors.map(({ message }) => message),
      });
    }
  }, [error]);

  return (
    <div className="get-started">
      <div className="get-started-container">
        <div className="logo-container">
          <img alt="Utogi Logo" src={require("assets/logo/utogi-logo.png")} />
        </div>
        <p className="heading">Setup Your Account</p>
        {isSuccessful ? (
          <>
            <div className="alert alert-success">
              Account setup is successful.
            </div>
            <p className="links">
              <Link to="/login">
                Please click here if you are not redirected within a few
                seconds.
              </Link>
            </p>
          </>
        ) : (
          <>
            <div className="form-group">
              <Input
                placeholder="New Password"
                onChange={(password: any) =>
                  onFieldChange({ ...fields, password })
                }
                error={errors.password}
                value={fields.password}
                type="password"
              />
            </div>
            <div className="form-group">
              <Input
                placeholder="Confirm new password"
                onChange={(confirmPassword: any) =>
                  onFieldChange({ ...fields, confirmPassword })
                }
                error={errors.confirmPassword}
                value={fields.confirmPassword}
                type="password"
              />
            </div>
            <div className="error-container">
              {errors.async.map((error: string, key: number) => (
                <div className="alert alert-danger" key={key}>
                  {error}
                </div>
              ))}
            </div>
            <div className="button-container">
              <Button hasShadow onClick={onSubmit} loading={loading}>
                Submit
              </Button>
              <GoogleLogin
                onSuccess={onGoogleLoginSuccess}
                loading={loading}
                onFailure={() => {}}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GetStarted;
