import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import config from "config";
import React from "react";

const client = (token: string | null) =>
  new ApolloClient({
    uri: `${config.DOMAIN_PROTOCOL}api.${config.DOMAIN_SUFFIX}/graphql`,
    request: (operation) => {
      operation.setContext({
        headers: {
          Authorization: token,
        },
      });
    },
  });

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

export default ({
  token,
  children,
}: {
  token: string | null;
  children: React.ReactNode;
}) => <ApolloProvider client={client(token)}>{children}</ApolloProvider>;
