import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Input, Button } from "@virtualcapital/utogi-ui-library";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { isPasswordStrong } from "helpers/validation";

import config from "config";

import "./ResetPassword.scss";

type Errors = {
  password: null | string;
  confirmPassword: null | string;
  async: [];
};

const ResetPassword = () => {
  const { token } = useParams();
  const [fields, onFieldChange] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<any>({
    password: null,
    confirmPassword: null,
    async: [],
  });
  const [isSuccessful, toggleSuccessful] = useState<any>(false);
  const REQUEST_PASSWORD_RESET = gql`
    mutation($token: String!, $password: String!) {
      auth {
        resetPassword(token: $token, password: $password) {
          success
        }
      }
    }
  `;

  const [resetPassword, { loading, error }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      update(_cache, { data }) {
        if (data?.auth?.resetPassword?.success) {
          toggleSuccessful(true);
          window.location.replace(
            `${config.DOMAIN_PROTOCOL}${config.DOMAIN_SUFFIX}`
          );
        }
      },
    }
  );

  const onSubmit = () => {
    const newErrors: Errors = {
      password: null,
      confirmPassword: null,
      async: [],
    };
    let hasError = false;
    const { password, confirmPassword } = fields;

    if (!password || password === "") {
      newErrors.password = "Password is required.";
      hasError = true;
    } else if (!isPasswordStrong(password)) {
      newErrors.password =
        "At least 8 characters including a number, special character, lowercase, and uppercase letters";
      hasError = true;
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm Password is required.";
      hasError = true;
    } else if (confirmPassword !== password) {
      newErrors.confirmPassword = "Passwords do not match.";
      hasError = true;
    }
    setErrors(newErrors);
    if (!hasError) {
      resetPassword({ variables: { token, password } });
    }
  };

  useEffect(() => {
    if (error) {
      setErrors({
        email: null,
        password: null,
        async: error.graphQLErrors.map(({ message }) => message),
      });
    }
  }, [error]);

  return (
    <div className="request-password-reset">
      <p className="heading">Reset your password</p>
      {isSuccessful ? (
        <>
          <div className="alert alert-success">
            Password reset is successful.
          </div>
          <p className="links">
            <Link to="/login">
              Please click here if you are not redirected within a few seconds.
            </Link>
          </p>
        </>
      ) : (
        <>
          <div className="form-group">
            <Input
              placeholder="New Password"
              onChange={(password: any) =>
                onFieldChange({ ...fields, password })
              }
              error={errors.password}
              value={fields.password}
              type="password"
            />
          </div>
          <div className="form-group">
            <Input
              placeholder="Confirm new password"
              onChange={(confirmPassword: any) =>
                onFieldChange({ ...fields, confirmPassword })
              }
              error={errors.confirmPassword}
              value={fields.confirmPassword}
              type="password"
            />
          </div>
          <div className="error-container">
            {errors.async.map((error: string, key: number) => (
              <div className="alert alert-danger" key={key}>
                {error}
              </div>
            ))}
          </div>
        </>
      )}
      <div className="button-container">
        <Button hasShadow onClick={onSubmit} loading={loading}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ResetPassword;
