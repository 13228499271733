import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import { saveToken } from "service";

import "./authenticate.scss";

const Authenticate = ({ location: { search } }: any) => {
  const { module, redirectTo, role, token } = queryString.parse(search);

  const AUTHENTICATE = gql`
    mutation($token: String!, $role: String, $module: ApplicationModules!) {
      auth {
        authenticate(token: $token, module: $module, role: $role) {
          token
        }
      }
    }
  `;

  const [
    authenticate,
    { loading: isAuthenticateLoading, error: authenticateError },
  ] = useMutation(AUTHENTICATE, {
    update(_cache, { data }) {
      if (data?.auth?.authenticate?.token) {
        const {
          auth: {
            authenticate: { token: newToken },
          },
        } = data;
        if (newToken) {
          saveToken(newToken);
          if (redirectTo) {
            window.location.replace(
              `${redirectTo.toString()}?token=${newToken}`
            );
          } else {
            window.location.replace(`/authenticate?token=${newToken}`);
          }
        }
      }
    },
  });

  const isInvalid = !token || token === "";

  useEffect(() => {
    if (!authenticateError && !isInvalid) {
      authenticate({
        variables: {
          token,
          module: module || "PROFILE",
          role: role || "",
        },
      });
    }
  }, [authenticateError, isInvalid]);

  return (
    <div className="authenticate">
      <div className="authenticate-container">
        <div className="logo-container">
          <img alt="Utogi Logo" src={require("assets/logo/utogi-logo.png")} />
        </div>
        {isInvalid && <div className="invalid-request">Invalid Request</div>}
        {isAuthenticateLoading && <>Loading</>}
      </div>
    </div>
  );
};

export default withRouter(Authenticate);
