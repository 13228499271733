import React from "react";
import { Route, Switch as RouterSwitch } from "react-router-dom";

import RequestPasswordReset from "containers/RequestPasswordReset";
import ResetPassword from "containers/ResetPassword";

import "./resetPassword.scss";

export default () => {
  return (
    <div className="reset-password">
      <div className="reset-password-container">
        <div className="logo-container">
          <img alt="Utogi Logo" src={require("assets/logo/utogi-logo.png")} />
        </div>
        <RouterSwitch>
          <Route path="/reset-password" exact>
            <RequestPasswordReset />
          </Route>
          <Route path="/reset-password/:token">
            <ResetPassword />
          </Route>
        </RouterSwitch>
      </div>
    </div>
  );
};
