import React, { Suspense, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthContext } from "@virtualcapital/utogi-auth-library";

import ApolloProvider from "./graphql/client";

import Loader from "./components/loader";
import LoginPage from "./pages/login";
import ResetPage from "./pages/resetPassword";
import GetStarted from "./pages/getStarted";
import Welcome from "./pages/welcome";
import ChangeEmail from "./pages/changeEmail";
import Authenticate from "./pages/authenticate";

import "./App.scss";

function App() {
  const {
    authData: { token },
  } = useContext(AuthContext);

  return (
    <ApolloProvider token={token}>
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route path="/reset-password">
              <ResetPage />
            </Route>
            <Route path="/get-started/:token">
              <GetStarted />
            </Route>
            <Route path="/change-email/:key">
              <ChangeEmail />
            </Route>
            <Route path="/auth">
              <Authenticate />
            </Route>
            <Route path="/">
              <Welcome />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </ApolloProvider>
  );
}

export default App;
