import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import config from "config";

import "./changeEmail.scss";

const ChangeEmail = () => {
  const { key } = useParams();
  const [errors, setErrors] = useState<any>([]);
  const [isSuccessful, toggleSuccessful] = useState<any>(false);
  const REQUEST_email_RESET = gql`
    mutation($key: String!) {
      user {
        changeEmail(key: $key) {
          success
        }
      }
    }
  `;

  const [changeEmail, { loading, error }] = useMutation(REQUEST_email_RESET, {
    update(_cache, { data }) {
      if (data?.user?.changeEmail?.success) {
        toggleSuccessful(true);
        window.location.replace(
          `${config.DOMAIN_PROTOCOL}${config.DOMAIN_SUFFIX}`
        );
      }
    },
  });

  useEffect(() => {
    if (key && !error) {
      changeEmail({ variables: { key } });
    }
    if (error) {
      setErrors(error.graphQLErrors.map(({ message }) => message));
    }
  }, [key, error]);

  return (
    <div className="change-email">
      <div className="change-email-container">
        <div className="logo-container">
          <img alt="Utogi Logo" src={require("assets/logo/utogi-logo.png")} />
        </div>
        <div>
          <p className="heading">Change Email</p>
          {isSuccessful && (
            <>
              <div className="alert alert-success">
                Email is changed successfully.
              </div>
              <p className="links">
                <Link to="/login">
                  Please click here if you are not redirected within a few
                  seconds.
                </Link>
              </p>
            </>
          )}
          {loading && <>Loading</>}
          <div className="error-container">
            {errors.map((error: string, key: number) => (
              <div className="alert alert-danger" key={key}>
                {error}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;
