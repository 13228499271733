export enum Maps {
  IS_AVAILABLE = 'isAvailable',
  UPDATE_MAP_SETTINGS = 'updateMapSettings',
  CREATE_NEW_CAMPAIGN = 'createNewCampaign',
  EDIT_CAMPAIGN = 'editCampaign',
  ARCHIVE_CAMPAIGN = 'archiveCampaign',
  DELETE_LAYERS = 'deleteLayers',
  INVITE_USERS_TO_CAMPAIGN_IN_GROUP = 'inviteUsersToCampaignInGroup',
  INVITE_USERS_TO_CAMPAIGN_IN_OFFICE = 'inviteUsersToCampaignInOffice',
  EDIT_PERMISSIONS_OF_CAMPAIGN = 'editPermissionsOfCampaign',
  VIEW_CAMPAIGNS_OF_GROUP = 'viewCampaignsOfGroup',
  ARCHIVE_CAMPAIGNS_OF_GROUP = 'archiveCampaignsOfGroup',
  EDIT_CAMPAIGNS_OF_GROUP = 'editCampaignsOfGroup',
  VIEW_CAMPAIGNS_OF_OFFICE = 'viewCampaignsOfOffice',
  ARCHIVE_CAMPAIGNS_OF_OFFICE = 'archiveCampaignsOfOffice',
  EDIT_CAMPAIGNS_OF_OFFICE = 'editCampaignsOfOffice',
  EDIT_LAYER_TYPES = 'editLayerTypes',
  CREATE_AND_EDIT_CAMPAIGN_TEMPLATES_OF_OFFICE = 'createAndEditCampaignTemplatesOfOffice',
  DELETE_CAMPAIGN_TEMPLATE_OF_OFFICE = 'deleteCampaignTemplateOfOffice',
  CREATE_AND_EDIT_CAMPAIGN_TEMPLATES_OF_GROUP = 'createAndEditCampaignTemplatesOfGroup',
  DELETE_CAMPAIGN_TEMPLATE_OF_GROUP = 'deleteCampaignTemplateOfGroup',
  VIEW_AND_EDIT_CAMPAIGN_TEMPLATES_OF_OFFICE = 'viewAndEditCampaignTemplatesOfOffice',
  DELETE_CAMPAIGN_TEMPLATES_CREATED_BY_OTHER_USERS_IN_OFFICE = 'deleteCampaignTemplatesOfOffice',
  ADD_LAYERS = 'addLayers',
  ADD_TAGS = 'addTags',
  ADD_NOTES = 'addNotes',
  SEND_EMAILS = 'sendEmails',
  ADD_REMINDERS = 'addReminders',
  VIEW_PROSPECT_MODE = 'viewProspectMode',
  VIEW_MARKET_VIEW = 'viewMarketView',
  VIEW_USER_VIEW = 'viewUserView',
  CREATE_REPORTS = 'createReports',
  VIEW_REPORTS = 'viewReports',
  EDIT_PROPERTY_DETAILS = 'editPropertyDetails',
}
