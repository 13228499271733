import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Input, Button } from "@virtualcapital/utogi-ui-library";

import { isEmail } from "helpers/validation";

import "./RequestPasswordReset.scss";

type Errors = {
  email: null | string;
  async: [];
};

const RequestPasswordReset = () => {
  const [fields, onFieldChange] = useState({
    email: "",
  });
  const [errors, setErrors] = useState<any>({
    email: null,
    password: null,
    async: [],
  });
  const [isSuccessful, toggleSuccessful] = useState<any>(false);
  const REQUEST_PASSWORD_RESET = gql`
    mutation($email: String!) {
      auth {
        requestPasswordReset(email: $email) {
          token
        }
      }
    }
  `;

  const [
    manualLogin,
    { loading: isManualLoginLoading, error: manualLoginError },
  ] = useMutation(REQUEST_PASSWORD_RESET, {
    update(_cache, { data }) {
      if (data?.auth?.requestPasswordReset?.token) {
        toggleSuccessful(true);
        setErrors({
          email: null,
          password: null,
          async: [],
        });
      }
    },
  });

  const onSubmit = () => {
    const newErrors: Errors = {
      email: null,
      async: [],
    };
    let hasError = false;
    const { email } = fields;

    if (!email || email === "") {
      newErrors.email = "Email is required.";
      hasError = true;
    }
    if (email && !isEmail(email)) {
      newErrors.email = "Email is invalid.";
      hasError = true;
    }
    setErrors(newErrors);
    if (!hasError) {
      manualLogin({ variables: { email, module: "MAPS" } });
    }
  };

  useEffect(() => {
    if (manualLoginError) {
      setErrors({
        email: null,
        password: null,
        async: manualLoginError.graphQLErrors.map(({ message }) => message),
      });
    }
  }, [manualLoginError]);

  return (
    <div className="request-password-reset">
      <p className="heading">Reset your password</p>
      {isSuccessful ? (
        <div className="alert alert-success">
          We sent a password reset link to your email. Click the link within 30
          mins or you'll need to request a new one. If you don't see the email,
          check your spam folder.
        </div>
      ) : (
        <>
          <div className="form-group">
            <Input
              placeholder="Email"
              onChange={(email: any) => onFieldChange({ ...fields, email })}
              error={errors.email}
              value={fields.email}
            />
          </div>
          <div className="error-container">
            {errors.async.map((error: string, key: number) => (
              <div className="alert alert-danger" key={key}>
                {error}
              </div>
            ))}
          </div>
          <div className="button-container">
            <Button hasShadow onClick={onSubmit} loading={isManualLoginLoading}>
              Submit
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default RequestPasswordReset;
