import React, { useContext, useEffect, ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import queryString from 'query-string';

import GraphQL from '../graphql/client';
import { AuthContext } from '../contexts';

const GET_PERMISSIONS = gql`
  query {
    auth {
      session {
        currentRole {
          title
          id
        }
        roles {
          title
          id
          office {
            name
          }
        }
        user
        permissions
        office {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  }
`;

type PermissionHandlerProps = {
  redirectTo: string;
  module: string;
  domainProtocol: string;
  domainSuffix: string;
  setSession: Function;
  permissions: object | null;
  token: string | null;
};

const PermissionHandler: React.FC<PermissionHandlerProps> = ({
  children,
  permissions,
  setSession,
  redirectTo,
  module,
  token,
  domainProtocol,
  domainSuffix,
}) => {
  const { loading, data, error } = useQuery(GET_PERMISSIONS, { skip: permissions !== null });
  if (error) {
    window.location.replace(
      `${domainProtocol}auth.${domainSuffix}/login?${queryString.stringify({ redirectTo, module })}`,
    );
  }
  useEffect(() => {
    if (data && !permissions) {
      const {
        auth: { session },
      } = data;
      const payload = {
        ...session,
        links: {
          logout: `${domainProtocol}auth.${domainSuffix}/logout`,
          companySettings: `${domainProtocol}company.${domainSuffix}`,
          officeSettings: session.office
            ? `${domainProtocol}auth.${domainSuffix}/auth?${queryString.stringify({
                token,
                redirectTo: `${domainProtocol}office.${domainSuffix}`,
                module: 'OFFICE',
                role: session.currentRole ? session.currentRole.id : null,
              })}`
            : null,
          mySettings: `${domainProtocol}profile.${domainSuffix}`,
          roles: session.roles.map(({ title, id: role, office: { name: office } }: any) => ({
            title,
            office,
            link: `${domainProtocol}auth.${domainSuffix}/auth?${queryString.stringify({
              ...(module !== 'COMPANY' && { module, redirectTo }),
              role,
              token,
            })}`,
          })),
        },
      };
      setSession(payload);
    }
  }, [data]);
  if (loading) {
    return <div>Loading</div>;
  }
  return <>{children}</>;
};

type SessionProps = {
  redirectURL: string;
  module: string;
  domainProtocol: string;
  domainSuffix: string;
  children: ReactNode;
};

export const Session = ({ children, redirectURL: redirectTo, module, domainProtocol, domainSuffix }: SessionProps) => {
  const authURL = `${domainProtocol}auth.${domainSuffix}/login?${queryString.stringify({ redirectTo, module })}`;
  const {
    authData: { token, permissions },
    setSession,
    setRedirect,
  } = useContext(AuthContext);

  useEffect(() => {
    setRedirect({ domain: `${domainProtocol}auth.${domainSuffix}`, redirectTo, module });
  }, []);

  if (!token) {
    window.location.replace(authURL);
  }
  return (
    <GraphQL token={token} apiUrl={`${domainProtocol}api.${domainSuffix}/graphql`}>
      <PermissionHandler
        permissions={permissions}
        setSession={setSession}
        redirectTo={redirectTo}
        module={module}
        domainProtocol={domainProtocol}
        domainSuffix={domainSuffix}
        token={token}
      >
        {permissions ? children : <div>Loading</div>}
      </PermissionHandler>
    </GraphQL>
  );
};
