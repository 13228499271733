/**
 * Currently All campaign permissions set at office level
 * Ref: https://virtual-capital.atlassian.net/wiki/spaces/UTOGI/pages/301170697/Marketing+User+Permissions
 */

export enum Marketing {
  IS_MARKETING_AVAILABLE = 'isMarketingAvailable',
  VIEW_GENERAL_CAMPAIGN = 'viewGeneralCampaign',
  VIEW_PROPERTY_CAMPAIGN = 'viewPropertyCampaign',
  CREATE_EDIT_GENERAL_CAMPAIGN = 'createEditGeneralCampaign',
  CREATE_EDIT_PROPERTY_CAMPAIGN = 'createEditPropertyCampaign',
  WITHDRAW_GENERAL_CAMPAIGN = 'withdrawGeneralCampaign',
  WITHDRAW_PROPERTY_CAMPAIGN = 'withdrawPropertyCampaign',

  // In Draft
  EDIT_MEDIA_IN_DRAFT_GENERAL_CAMPAIGN_IN_OFFICE = 'editMediaInDraftGeneralCampaignInOffice',
  EDIT_ADVERTISING_TEXT_IN_DRAFT_GENERAL_CAMPAIGN_IN_OFFICE = 'editAdvertisingTextInDraftGeneralCampaignInOffice',
  EDIT_ONLINE_MARKETING_IN_DRAFT_GENERAL_CAMPAIGN_IN_OFFICE = 'editOnlineMarketingInDraftGeneralCampaignInOffice',
  EDIT_PRINT_COLLATERAL_IN_DRAFT_GENERAL_CAMPAIGN_IN_OFFICE = 'editPrintCollateralInDraftGeneralCampaignInOffice',
  EDIT_TIMELINE_IN_DRAFT_GENERAL_CAMPAIGN_IN_OFFICE = 'editTimelineInDraftGeneralCampaignInOffice',
  ACTIVE_A_DRAFT_GENERAL_CAMPAIGN_IN_OFFICE = 'activeADraftGeneralCampaignInOffice',

  EDIT_MEDIA_IN_DRAFT_PROPERTY_CAMPAIGN_IN_OFFICE = 'editMediaInDraftPropertyCampaignInOffice',
  EDIT_ADVERTISING_TEXT_IN_DRAFT_PROPERTY_CAMPAIGN_IN_OFFICE = 'editAdvertisingTextInDraftPropertyCampaignInOffice',
  EDIT_ONLINE_MARKETING_IN_DRAFT_PROPERTY_CAMPAIGN_IN_OFFICE = 'editOnlineMarketingInDraftPropertyCampaignInOffice',
  EDIT_PRINT_COLLATERAL_IN_DRAFT_PROPERTY_CAMPAIGN_IN_OFFICE = 'editPrintCollateralInDraftPropertyCampaignInOffice',
  EDIT_TIMELINE_IN_DRAFT_PROPERTY_CAMPAIGN_IN_OFFICE = 'editTimelineInDraftPropertyCampaignInOffice',
  ACTIVE_A_DRAFT_PROPERTY_CAMPAIGN_IN_OFFICE = 'activeADraftPropertyCampaignInOffice',

  EDIT_MEDIA_IN_DRAFT_GENERAL_CAMPAIGN_OF_GROUP = 'editMediaInDraftGeneralCampaignOfGroup',
  EDIT_ADVERTISING_TEXT_IN_DRAFT_GENERAL_CAMPAIGN_OF_GROUP = 'editAdvertisingTextInDraftGeneralCampaignOfGroup',
  EDIT_ONLINE_MARKETING_IN_DRAFT_GENERAL_CAMPAIGN_OF_GROUP = 'editOnlineMarketingInDraftGeneralCampaignOfGroup',
  EDIT_PRINT_COLLATERAL_IN_DRAFT_GENERAL_CAMPAIGN_OF_GROUP = 'editPrintCollateralInDraftGeneralCampaignOfGroup',
  EDIT_TIMELINE_IN_DRAFT_GENERAL_CAMPAIGN_OF_GROUP = 'editTimelineInDraftGeneralCampaignOfGroup',
  ACTIVE_A_DRAFT_GENERAL_CAMPAIGN_OF_GROUP = 'activeADraftGeneralCampaignOfGroup',

  EDIT_MEDIA_IN_DRAFT_PROPERTY_CAMPAIGN_OF_GROUP = 'editMediaInDraftPropertyCampaignOfGroup',
  EDIT_ADVERTISING_TEXT_IN_DRAFT_PROPERTY_CAMPAIGN_OF_GROUP = 'editAdvertisingTextInDraftPropertyCampaignOfGroup',
  EDIT_ONLINE_MARKETING_IN_DRAFT_PROPERTY_CAMPAIGN_OF_GROUP = 'editOnlineMarketingInDraftPropertyCampaignOfGroup',
  EDIT_PRINT_COLLATERAL_IN_DRAFT_PROPERTY_CAMPAIGN_OF_GROUP = 'editPrintCollateralInDraftPropertyCampaignOfGroup',
  EDIT_TIMELINE_IN_DRAFT_PROPERTY_CAMPAIGN_OF_GROUP = 'editTimelineInDraftPropertyCampaignOfGroup',
  ACTIVE_A_DRAFT_PROPERTY_CAMPAIGN_OF_GROUP = 'activeADraftPropertyCampaignOfGroup',

  EDIT_MEDIA_IN_DRAFT_GENERAL_CAMPAIGN_OF_OWN = 'editMediaInDraftGeneralCampaignOfOwn',
  EDIT_ADVERTISING_TEXT_IN_DRAFT_GENERAL_CAMPAIGN_OF_OWN = 'editAdvertisingTextInDraftGeneralCampaignOfOwn',
  EDIT_ONLINE_MARKETING_IN_DRAFT_GENERAL_CAMPAIGN_OF_OWN = 'editOnlineMarketingInDraftGeneralCampaignOfOwn',
  EDIT_PRINT_COLLATERAL_IN_DRAFT_GENERAL_CAMPAIGN_OF_OWN = 'editPrintCollateralInDraftGeneralCampaignOfOwn',
  EDIT_TIMELINE_IN_DRAFT_GENERAL_CAMPAIGN_OF_OWN = 'editTimelineInDraftGeneralCampaignOfOwn',
  ACTIVE_A_DRAFT_GENERAL_CAMPAIGN_OF_OWN = 'activeADraftGeneralCampaignOfOwn',

  EDIT_MEDIA_IN_DRAFT_PROPERTY_CAMPAIGN_OF_OWN = 'editMediaInDraftPropertyCampaignOfOwn',
  EDIT_ADVERTISING_TEXT_IN_DRAFT_PROPERTY_CAMPAIGN_OF_OWN = 'editAdvertisingTextInDraftPropertyCampaignOfOwn',
  EDIT_ONLINE_MARKETING_IN_DRAFT_PROPERTY_CAMPAIGN_OF_OWN = 'editOnlineMarketingInDraftPropertyCampaignOfOwn',
  EDIT_PRINT_COLLATERAL_IN_DRAFT_PROPERTY_CAMPAIGN_OF_OWN = 'editPrintCollateralInDraftPropertyCampaignOfOwn',
  EDIT_TIMELINE_IN_DRAFT_PROPERTY_CAMPAIGN_OF_OWN = 'editTimelineInDraftPropertyCampaignOfOwn',
  ACTIVE_A_DRAFT_PROPERTY_CAMPAIGN_OF_OWN = 'activeADraftPropertyCampaignOfOwn',

  // In Active
  EDIT_MEDIA_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'editMediaInActiveGeneralCampaignInOffice',
  EDIT_ADVERTISING_TEXT_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'editAdvertisingTextInActiveGeneralCampaignInOffice',
  EDIT_ONLINE_MARKETING_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'editOnlineMarketingInActiveGeneralCampaignInOffice',
  EDIT_PRINT_COLLATERAL_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'editPrintCollateralInActiveGeneralCampaignInOffice',
  ADD_ITEM_TO_TIMELINE_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'addItemsToTimelineInActiveGeneralCampaignInOffice',
  DELETE_ITEM_FROM_TIMELINE_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'deleteItemsFromTimelineInActiveGeneralCampaignInOffice',
  MARK_MARKETING_ITEM_AS_PAID_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'markMarketingItemAsPaidInActiveGeneralCampaignInOffice',
  CREATE_AN_INVOICE_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'createAnInvoiceInActiveGeneralCampaignInOffice',
  VIEW_STATISTICS_IN_ACTIVE_GENERAL_CAMPAIGN_IN_OFFICE = 'viewStatisticsInActiveGeneralCampaignInOffice',

  EDIT_MEDIA_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'editMediaInActivePropertyCampaignInOffice',
  EDIT_ADVERTISING_TEXT_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'editAdvertisingTextInActivePropertyCampaignInOffice',
  EDIT_ONLINE_MARKETING_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'editOnlineMarketingInActivePropertyCampaignInOffice',
  EDIT_PRINT_COLLATERAL_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'editPrintCollateralInActivePropertyCampaignInOffice',
  ADD_ITEM_TO_TIMELINE_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'addItemsToTimelineInActivePropertyCampaignInOffice',
  DELETE_ITEM_FROM_TIMELINE_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'deleteItemsFromTimelineInActivePropertyCampaignInOffice',
  MARK_MARKETING_ITEM_AS_PAID_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'markMarketingItemAsPaidInActivePropertyCampaignInOffice',
  CREATE_AN_INVOICE_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'createAnInvoiceInActivePropertyCampaignInOffice',
  VIEW_STATISTICS_IN_ACTIVE_PROPERTY_CAMPAIGN_IN_OFFICE = 'viewStatisticsInActivePropertyCampaignInOffice',

  EDIT_MEDIA_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'editMediaInActiveGeneralCampaignOfGroup',
  EDIT_ADVERTISING_TEXT_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'editAdvertisingTextInActiveGeneralCampaignOfGroup',
  EDIT_ONLINE_MARKETING_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'editOnlineMarketingInActiveGeneralCampaignOfGroup',
  EDIT_PRINT_COLLATERAL_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'editPrintCollateralInActiveGeneralCampaignOfGroup',
  ADD_ITEM_TO_TIMELINE_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'addItemsToTimelineInActiveGeneralCampaignOfGroup',
  DELETE_ITEM_FROM_TIMELINE_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'deleteItemsFromTimelineInActiveGeneralCampaignOfGroup',
  MARK_MARKETING_ITEM_AS_PAID_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'markMarketingItemAsPaidInActiveGeneralCampaignOfGroup',
  CREATE_AN_INVOICE_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'createAnInvoiceInActiveGeneralCampaignOfGroup',
  VIEW_STATISTICS_IN_ACTIVE_GENERAL_CAMPAIGN_OF_GROUP = 'viewStatisticsInActiveGeneralCampaignOfGroup',

  EDIT_MEDIA_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'editMediaInActivePropertyCampaignOfGroup',
  EDIT_ADVERTISING_TEXT_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'editAdvertisingTextInActivePropertyCampaignOfGroup',
  EDIT_ONLINE_MARKETING_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'editOnlineMarketingInActivePropertyCampaignOfGroup',
  EDIT_PRINT_COLLATERAL_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'editPrintCollateralInActivePropertyCampaignOfGroup',
  ADD_ITEM_TO_TIMELINE_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'addItemsToTimelineInActivePropertyCampaignOfGroup',
  DELETE_ITEM_FROM_TIMELINE_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'deleteItemsFromTimelineInActivePropertyCampaignOfGroup',
  MARK_MARKETING_ITEM_AS_PAID_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'markMarketingItemAsPaidInActivePropertyCampaignOfGroup',
  CREATE_AN_INVOICE_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'createAnInvoiceInActivePropertyCampaignOfGroup',
  VIEW_STATISTICS_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_GROUP = 'viewStatisticsInActivePropertyCampaignOfGroup',

  EDIT_MEDIA_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'editMediaInActiveGeneralCampaignOfOwn',
  EDIT_ADVERTISING_TEXT_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'editAdvertisingTextInActiveGeneralCampaignOfOwn',
  EDIT_ONLINE_MARKETING_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'editOnlineMarketingInActiveGeneralCampaignOfOwn',
  EDIT_PRINT_COLLATERAL_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'editPrintCollateralInActiveGeneralCampaignOfOwn',
  ADD_ITEM_TO_TIMELINE_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'addItemsToTimelineInActiveGeneralCampaignOfOwn',
  DELETE_ITEM_FROM_TIMELINE_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'deleteItemsFromTimelineInActiveGeneralCampaignOfOwn',
  MARK_MARKETING_ITEM_AS_PAID_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'markMarketingItemAsPaidInActiveGeneralCampaignOfOwn',
  CREATE_AN_INVOICE_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'createAnInvoiceInActiveGeneralCampaignOfOwn',
  VIEW_STATISTICS_IN_ACTIVE_GENERAL_CAMPAIGN_OF_OWN = 'viewStatisticsInActiveGeneralCampaignOfOwn',

  EDIT_MEDIA_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'editMediaInActivePropertyCampaignOfOwn',
  EDIT_ADVERTISING_TEXT_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'editAdvertisingTextInActivePropertyCampaignOfOwn',
  EDIT_ONLINE_MARKETING_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'editOnlineMarketingInActivePropertyCampaignOfOwn',
  EDIT_PRINT_COLLATERAL_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'editPrintCollateralInActivePropertyCampaignOfOwn',
  ADD_ITEM_TO_TIMELINE_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'addItemsToTimelineInActivePropertyCampaignOfOwn',
  DELETE_ITEM_FROM_TIMELINE_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'deleteItemsFromTimelineInActivePropertyCampaignOfOwn',
  MARK_MARKETING_ITEM_AS_PAID_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'markMarketingItemAsPaidInActivePropertyCampaignOfOwn',
  CREATE_AN_INVOICE_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'createAnInvoiceInActivePropertyCampaignOfOwn',
  VIEW_STATISTICS_IN_ACTIVE_PROPERTY_CAMPAIGN_OF_OWN = 'viewStatisticsInActivePropertyCampaignOfOwn',

  // Setting & Payment
  // Marketing Options
  ADD_SUPPLIERS_IN_OFFICE = 'addSuppliersCampaignInOffice',
  CREATE_EDIT_MARKETING_ITEM_AND_PRICE_IN_OFFICE = 'createEditMarketingItemAndPriceInOffice',

  // Payments
  VIEW_EDIT_OFFICE_DETAILS_FOR_RECEIVING_PAYMENTS_IN_OFFICE = 'viewEditOfficeDetailsForReceivingPaymentsInOffice',
  PAY_FOR_CAMPAIGN_ITEM_USING_OFFICE_CREDIT_CARD_PAYMENT_OPTION_IN_OFFICE = 'payForCampaignItemUsingOfficeCreditCardPaymentOptionInOffice',

  VIEW_EDIT_OFFICE_DETAILS_FOR_RECEIVING_PAYMENTS_OF_OWN = 'viewEditOfficeDetailsForReceivingPaymentsOfOwn',
  PAY_FOR_CAMPAIGN_ITEM_USING_OFFICE_CREDIT_CARD_PAYMENT_OPTION_OF_OWN = 'payForCampaignItemUsingOfficeCreditCardPaymentOptionOfOwn',

  // Payments
  UPDATE_INTEGRATIONS_INFORMATION_IN_OFFICE = 'updateIntegrationInformationInOffice',
  WHO_CAN_THIS_USER_CREATE_CAMPAIGNS_ON_BEHALF_OF_FACEBOOK_GOOGLE_INTEGRATIONS_IN_OFFICE = 'whoCanThisUserCreateCampaignsOnBehalfOfFacebookAndGoogleIntegrationsInOffice',

  UPDATE_INTEGRATIONS_INFORMATION_OF_OWN = 'updateIntegrationInformationOfOwn',
  WHO_CAN_THIS_USER_CREATE_CAMPAIGNS_ON_BEHALF_OF_FACEBOOK_GOOGLE_INTEGRATIONS_OF_OWN = 'whoCanThisUserCreateCampaignsOnBehalfOfFacebookAndGoogleIntegrationsOfOwn',
}
