import React from "react";

import { Button, ButtonType } from "@virtualcapital/utogi-ui-library";
import { ReactComponent as GoogleIcon } from "assets/logo/google.svg";

import useGoogleLogin from "helpers/useGoogleLogin";
import config from "config";

import "./GoogleLogin.scss";

const GoogleLogin = ({
  className,
  disabled: disabledProp,
  onSuccess,
  loginHint,
  hostedDomain,
  autoLoad,
  redirectUri,
  discoveryDocs,
  onFailure,
  responseType,
  jsSrc,
  cookiePolicy = "single_host_origin",
  isSignedIn = false,
  fetchBasicProfile = true,
  uxMode = "popup",
  scope = "profile email",
  accessType = "online",
  onRequest = () => {},
  prompt = "",
  ...rest
}: any) => {
  const { signIn, loaded } = useGoogleLogin({
    onSuccess,
    clientId: config.GOOGLE_CLIENT_ID,
    cookiePolicy,
    loginHint,
    hostedDomain,
    autoLoad,
    isSignedIn,
    fetchBasicProfile,
    redirectUri,
    discoveryDocs,
    onFailure,
    uxMode,
    scope,
    accessType,
    responseType,
    jsSrc,
    onRequest,
    prompt,
  });
  const disabled = disabledProp || !loaded;
  return (
    <Button
      hasShadow
      type={ButtonType.DEFAULT}
      onClick={signIn}
      disabled={disabled}
      className={className}
      {...rest}
    >
      <GoogleIcon />
      Continue with Google
    </Button>
  );
};

export default GoogleLogin;
