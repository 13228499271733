import { useState, useEffect } from "react";

declare global {
  interface Window {
    gapi: any;
  }
}

const loadScript = (d: any, s: any, id: any, jsSrc: any, cb: any) => {
  const element = d.getElementsByTagName(s)[0];
  const fjs = element;
  let js = element;
  js = d.createElement(s);
  js.id = id;
  js.src = jsSrc;
  if (fjs && fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    d.head.appendChild(js);
  }
  js.onload = cb;
};

const removeScript = (d: any, id: any) => {
  const element = d.getElementById(id);

  if (element) {
    element.parentNode.removeChild(element);
  }
};

const useGoogleLogin = ({
  onSuccess,
  clientId,
  cookiePolicy,
  loginHint,
  hostedDomain,
  autoLoad,
  isSignedIn,
  fetchBasicProfile,
  redirectUri,
  discoveryDocs,
  onFailure,
  uxMode,
  scope,
  accessType,
  responseType,
  jsSrc = "https://apis.google.com/js/api.js",
  onRequest,
  prompt,
}: any) => {
  const [loaded, setLoaded] = useState(false);

  function handleSignInSuccess(res: any) {
    const basicProfile = res.getBasicProfile();
    const authResponse = res.getAuthResponse();
    res.googleId = basicProfile.getId();
    res.tokenObj = authResponse;
    res.tokenId = authResponse.id_token;
    res.accessToken = authResponse.access_token;
    res.profileObj = {
      googleId: basicProfile.getId(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName(),
    };
    onSuccess(res);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function signIn(e: null | any) {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    if (loaded) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      const options = {
        prompt,
      };
      onRequest();
      if (responseType === "code") {
        auth2.grantOfflineAccess(options).then(
          (res: any) => onSuccess(res),
          (err: any) => onFailure(err)
        );
      } else {
        auth2.signIn(options).then(
          (res: any) => handleSignInSuccess(res),
          (err: any) => onFailure(err)
        );
      }
    }
  }
  useEffect(() => {
    let unmounted = false;
    loadScript(document, "script", "google-login", jsSrc, () => {
      const params = {
        client_id: clientId,
        cookie_policy: cookiePolicy,
        login_hint: loginHint,
        hosted_domain: hostedDomain,
        fetch_basic_profile: fetchBasicProfile,
        discoveryDocs,
        ux_mode: uxMode,
        redirect_uri: redirectUri,
        scope,
        access_type: accessType,
      };

      if (responseType === "code") {
        params.access_type = "offline";
      }

      window.gapi.load("auth2", () => {
        if (!window.gapi.auth2.getAuthInstance()) {
          window.gapi.auth2.init(params).then(
            (res: any) => {
              if (!unmounted) {
                setLoaded(true);
                if (isSignedIn && res.isSignedIn.get()) {
                  handleSignInSuccess(res.currentUser.get());
                }
              }
            },
            (err: any) => onFailure(err)
          );
        } else if (isSignedIn && window.gapi.auth2.isSignedIn.get()) {
          handleSignInSuccess(window.gapi.auth2.currentUser.get());
        } else if (!unmounted) {
          setLoaded(true);
        }
      });
    });

    return () => {
      unmounted = true;
      removeScript(document, "google-login");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientId,
    cookiePolicy,
    loginHint,
    hostedDomain,
    fetchBasicProfile,
    uxMode,
    redirectUri,
    accessType,
    discoveryDocs,
    scope,
    isSignedIn,
    jsSrc,
    onFailure,
    responseType,
  ]);

  useEffect(() => {
    if (autoLoad) {
      signIn(null);
    }
  }, [loaded, autoLoad, signIn]);

  return { signIn, loaded };
};

export default useGoogleLogin;
