export enum OfficePermissions {
  CREATE_NEW_OFFICE = 'createNewOffice',

  VIEW_OFFICE_PROFILE = 'viewOfficeProfile',
  EDIT_OFFICE_PROFILE = 'editOfficeProfile',

  VIEW_OFFICE_MARKETING_PROFILE = 'viewOfficeMarketingProfile',
  EDIT_OFFICE_MARKETING_PROFILE = 'editOfficeMarketingProfile',

  VIEW_OFFICE_MEDIA = 'viewOfficeMedia',
  EDIT_OFFICE_MEDIA = 'editOfficeMedia',

  VIEW_OFFICE_LICENSES_BILLING = 'viewOfficeLicensesBilling',
  EDIT_OFFICE_LICENSES_BILLING = 'editOfficeLicensesBilling',

  VIEW_OFFICE_USERS = 'viewOfficeUsers',
  EDIT_OFFICE_USERS = 'editOfficeUsers',

  VIEW_OFFICE_GROUPS = 'viewOfficeGroups',
  EDIT_OFFICE_GROUPS = 'editOfficeGroups',

  VIEW_OFFICE_TIMELINE = 'viewOfficeTimeline',
  EDIT_OFFICE_TIMELINE = 'editOfficeTimeline',
}
