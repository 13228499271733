import React, { createContext, useReducer } from 'react';
import { AUTH_TOKEN_KEY } from '../constants';
import { IAuthData, ILoginResponse, ISessionResponse, IRedirect } from '../types/auth';
import { getToken, handleAuthDataFromAPI } from '../service';

type AuthAction =
  | { type: 'LOGIN'; payload: ILoginResponse }
  | { type: 'LOGOUT' }
  | { type: 'SET_SESSION'; payload: ISessionResponse }
  | { type: 'SET_REDIRECT'; payload: IRedirect };

const INITIAL_STATE: IAuthData = {
  token: getToken(AUTH_TOKEN_KEY),
  redirectUrl: null,
  user: null,
  permissions: null,
  currentRole: null,
  roles: null,
  links: null,
  office: null,
  company: null,
};

const authReducer = (state: IAuthData, action: AuthAction) => {
  switch (action.type) {
    case 'LOGIN':
      const authData = handleAuthDataFromAPI(action.payload, AUTH_TOKEN_KEY);
      return {
        ...state,
        ...authData,
      };
    case 'SET_SESSION':
      return {
        ...state,
        ...action.payload,
      };
    case 'LOGOUT':
      return {
        token: null,
        user: null,
        permissions: null,
        currentRole: null,
        roles: null,
        redirectUrl: null,
        links: null,
        office: null,
        company: null,
      };
    default:
      return state;
  }
};

export const AuthContext = createContext({
  authData: INITIAL_STATE,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: (_loginResponse: ILoginResponse) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSession: (_sessionResponse: ISessionResponse) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setRedirect: (_redirectPayload: IRedirect) => {},
  logout: () => {},
});

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [authData, dispatch] = useReducer(authReducer, INITIAL_STATE);

  const login = (payload: ILoginResponse) => {
    dispatch({
      type: 'LOGIN',
      payload,
    });
  };

  const setSession = (payload: ISessionResponse) => {
    dispatch({
      type: 'SET_SESSION',
      payload,
    });
  };

  const setRedirect = (payload: IRedirect) => {
    dispatch({
      type: 'SET_REDIRECT',
      payload,
    });
  };

  const logout = () => dispatch({ type: 'LOGOUT' });

  return (
    <AuthContext.Provider value={{ authData, login, logout, setSession, setRedirect }}>{children}</AuthContext.Provider>
  );
};
