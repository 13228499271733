import React from "react";
import { Route, Switch as RouterSwitch } from "react-router-dom";

import WelcomePage from "./welcome";
import { AuthProvider, Authenticate } from "@virtualcapital/utogi-auth-library";

import config from "config";

const Welcome = () => {
  return (
    <AuthProvider
      callbackURL="/authenticate"
      module="MAPS"
      appURL={`${config.DOMAIN_PROTOCOL}auth.${config.DOMAIN_SUFFIX}`}
      domainProtocol={config.DOMAIN_PROTOCOL}
      domainSuffix={config.DOMAIN_SUFFIX}
    >
      <RouterSwitch>
        <Route exact path="/authenticate" component={Authenticate}></Route>
      </RouterSwitch>
      <WelcomePage />
    </AuthProvider>
  );
};

export default Welcome;
