export enum Contacts {
  IS_CONTACTS_AVAILABLE = 'isContactsAvailable',
  VIEW_INFORMATION_SET_TO_PRIVATE_IN_COMPANY = 'viewInformationSetToPrivateInCompany',
  VIEW_INFORMATION_SET_TO_PRIVATE_IN_OFFICE = 'viewInformationSetToPrivateInOffice',
  VIEW_INFORMATION_SET_TO_PRIVATE_IN_GROUP = 'viewInformationSetToPrivateInGroup',

  DELETE_INFORMATION_SET_TO_PRIVATE_IN_COMPANY = 'deleteInformationSetToPrivateInCompany',
  DELETE_INFORMATION_SET_TO_PRIVATE_IN_OFFICE = 'deleteInformationSetToPrivateInOffice',
  DELETE_INFORMATION_SET_TO_PRIVATE_IN_GROUP = 'deleteInformationSetToPrivateInGroup',

  UPDATE_CONTACTS_SETTINGS_IN_GROUP = 'updateContactsSettingsInGroup',
  UPDATE_CONTACT_INFORMATION = 'updateContactInformation',
}
